<template>
	<Error :errors="errors"/>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
			<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
				<div class="text-700 text-center">
					<div class="text-blue-600 font-bold mb-3">&nbsp;SELAMAT DATANG DI</div>
					<div class="text-900 font-bold text-5xl mb-3">Web Report - {{appsName}}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="grid">
		<div class="col-12 md:col-6 lg:col-3">
			<UserAktif/>
        </div>
		<div class="col-12 md:col-6 lg:col-3">
			<Call/>
        </div>
	</div>

    <div class="grid">
        <div class="col-12">
            <div class="card">
				<div class="p-fluid">
					<div class="formgrid grid">
						<div class="field col-12 md:col-6 lg:col-3">
							<label for="period">Periode</label>
							<Calendar selectionMode="range"  v-tooltip.top="'Maksimal 30 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
						</div>
						<div class="field col-12 md:col-6 lg:col-3">
							<label>User</label>
							<Dropdown id="userid" dataKey="userid" v-model="filters.userid" :loading="loadingDropdownUser"
								:options="dataDropdownUser"
								optionLabel="label" optionValue="userid" placeholder="Pilih User"
								:filter="true" :showClear="true" @filter="searchDropdownUser($event, 'filter')"/>
						</div>
					</div>
				</div>
				<div class="grid">
					<div class="col-12 md:col-6 lg:col-3">
						<OutletTransactions :filter="filters" :startDate="period_start_label" :endDate="period_end_label"/>
					</div>
					<div class="col-12 md:col-6 lg:col-3">
						<ValueTransactions :filter="filters" :startDate="period_start_label" :endDate="period_end_label"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import UserAktif from '../components/dashboard/UserAktif.vue';
import Call from '../components/dashboard/Call.vue';
import OutletTransactions from '../components/dashboard/OutletTransactions.vue';
import ValueTransactions from '../components/dashboard/ValueTransactions.vue';
import Error from '../components/Error.vue';
import moment from 'moment';

export default {
	components: {
		'UserAktif': UserAktif,
		'Call': Call,
		'OutletTransactions': OutletTransactions,
		'ValueTransactions': ValueTransactions,
		'Error': Error,
	},
	data() {
		return {		
			appsName: process.env.VUE_APP_APPS_NAME,

            // loading
            loadingDropdownUser: false,

            // dataDropdown
            dataDropdownUser: null,

            // filter
            filters: {
                userid: null,
            },

            period: [new Date(), new Date()],
            maxDate: null,
            minDate: null,
		}
	},
    watch: {
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        ...mapGetters(['errors']),
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
	methods: {
        searchDropdownUser(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownUser = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/user-hp2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownUser = res.data.data;
                            this.loadingDropdownUser = false;
                        } else if (purpose == null) {
                            this.dataDropdownUser = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
	}
}
</script>